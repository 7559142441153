.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.7);
}

.content {
  padding: 15px;
  background-color: #444;
  color: var(--txtcolor);
  width: 100%;
  max-width: 500px;
  margin: 15px;
}

.content p {
  margin: 10px 0;
}

.actions {
  width: 100%;
  display: flex;
}

.actions div {
  flex: 1;
  text-align: right;
}

.flexer {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}
.flexer div {
  flex: 1;
}

.content label {
  font-size: 12px;
}

.input {
  width: 100%;
  padding: 3px;
}

.error {
  font-size: 14px;
  color: rgb(255, 120, 120);
  margin-bottom: 5px;
}

.loader {
  text-align: center;
}

.checkbox {
  margin-right: 5px;
}

/* @media (max-width: 530px) {
  .content {
    width: 100%;
  }
} */