
.navWrapper li a {
  color: var(--txtcolor);
}
.navWrapper li {
  display: inline-block;
  list-style: none;
  padding: 10px 20px;
}

.navWrapper {
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navList {
  max-width: 800px;
}

.logo{
  padding: 20px 0;
  flex: 1;
  font-size: 2em;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

@media (max-width: 1024px) { 
  .nav {
    display: block;
    position: relative;
  }
  .navWrapper {
    padding: 0 15px;
    display: block;
    width: 100%;
    text-align: center;
  }
  .navList {
    width: 100%;
    padding-bottom: 20px;
    margin-left: 0;
    padding-left: 0;
  }

  .logo{
    padding: 10px 0;
    flex: auto;
    font-size: 2em;
    width: 100%;
    text-align: center;
  }
}