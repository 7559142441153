.flexer {
  width: 100%;
  display: flex;
  gap: 10px;
}

.flexer div {
  flex: 1;
  display: flex;
  justify-content: center;
}

.flexer div img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}
.gallerijintro {
  margin: 15px 0px;
}
.gallerijintro p {
  margin-bottom: 15px;
}

.gallerijcontent {
  margin-bottom: 25px;
}

.gallerijitem {
  background-color: #444;
  padding: 15px;
  margin: 35px 0;
}

.readmore {
  padding: 25px 0;
}
.readmore a  {
  color: var(--txtcolor);
}