.container {
  display: flex;
  justify-content: center;
}
.content {
  width: 1000px;
}

@media (max-width: 1024px) {
  .content {
    width: auto;
    padding: 15px;
  }
}