.footerContainer {
  width: 100%;
  border-top: solid 1px #777;
  color: #777;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  margin-top: 50px;
}

.privacy {
  flex: 1;
  text-align: right;
}

.footerContainer a {
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .footerContainer {
    width: calc(100vw - 30px);
  }
}