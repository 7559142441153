.primary {
  background-color: var(--primary);
  border: solid 1px var(--primary);
  border-radius: 4px;
  padding: 10px 20px;
  color: var(--txtcolor);
}

.secondary {
  color: var(--txtcolor);
  border: solid 1px var(--txtcolor);
  border-radius: 4px;
  padding: 10px 20px;
  background: transparent;
}

.disabled {
  border: solid 1px grey;
  border-radius: 4px;
  padding: 10px 20px;
  background-color: #ccc;
}

button:disabled,
button[disabled]{
  background-color: #cccccc;
}

.secondary:hover, .primary:hover {
  text-decoration: underline;
  cursor: pointer;
}