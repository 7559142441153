.card {
  background-color: #444;
  color: var(--txtcolor);
  padding: 15px;
  box-shadow: 0 0 0 #333;
  width: calc(50% - 100px);
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 55px;
}

.card:hover {
  box-shadow: 5px 5px 0px #555;
}

.title {
  font-size: 1.8em;
  padding-bottom: 5px;
}

.datetime {
  font-size: 12px;
  color: #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: solid 1px #888;
}

.location,
.description,
.price {
  padding-bottom: 10px;
}

.description {
  flex: 1;
}

.descriptor {
  font-size: 12px;
}

.carddate {
  position: absolute;
  top: 10px;
  left: -55px;
  background-color: var(--primary);
  color: var(--txtcolor);
  padding: 5px 10px;
  width: 60px;
}
.carddate::after {
  content: " ";
  width: calc(100% - 5px);
  height: 15px;
  background: rgb(52, 218, 0);
  background: linear-gradient(
    338deg,
    rgba(52, 218, 0, 1) 0%,
    rgb(11, 149, 43) 100%
  );
  clip-path: polygon(100% 100%, 100% 0%, 0% 0%);
  position: absolute;
  bottom: -15px;
  left: 0;
  z-index: 100;
}

.dateday {
  font-size: 30px;
  font-weight: bold;
}

@media (max-width: 800px) {
  .card {
    width: calc(100% - 50px);
    margin-bottom: 25px;
    margin-left: 50px;
  }
}
