.homeHero {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -2;
  overflow: hidden;
  text-align: center;
}

.homeHero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}

.homeAction {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.homeWrapper {
  width: 1000px;
}

.homeMission, .homePresentation{
  padding: 25px 0px;
}

.homePresentation {
  font-size: 12px;
}

@media (max-width: 1024px) {
  .homeWrapper {
    width: auto;
  }
  .homeAction {
    padding-left: 50px;
    align-items: flex-start;
  }
}