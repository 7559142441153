.loadWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}

.content {
  margin-top: 25px;
}

.content p {
  margin-bottom: 15px;
}