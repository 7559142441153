.loadWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}

.blogitem {
  margin: 25px 0px 25px 55px;
  position: relative;
  padding: 25px 30px;
  border: solid 1px #444;
  background-color: #444;
}

.blogitem:hover {
  cursor: pointer;
}

.blogdate {
  position: absolute;
  top: 10px;
  left: -55px;
  background-color: var(--primary);
  color: var(--txtcolor);
  padding: 5px 10px;
  width: 60px;
}
.blogdate::after {
  content: " ";
  width: calc(100% - 5px);
  height: 15px;
  background: rgb(52, 218, 0);
  background: linear-gradient(
    338deg,
    rgba(52, 218, 0, 1) 0%,
    rgb(11, 149, 43) 100%
  );
  clip-path: polygon(100% 100%, 100% 0%, 0% 0%);
  position: absolute;
  bottom: -15px;
  left: 0;
  z-index: 100;
}

.blogday {
  font-size: 30px;
  font-weight: bold;
}


.readmore {
  font-style: italic;
}