.AppLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Loader {
  text-align: center;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  padding: 15px;
  max-width: 500px;
  width: 100%;
}
.errorAction {
  margin: 15px 0;
}
.bigText {
  margin-bottom: 25px;
  font-size: 24px;
}
