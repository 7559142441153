.intro {
  margin: 35px 0 20px 0;
}

.table_offer {
  border-collapse: collapse;
}
.table_offer td {
  border: solid 1px #f1f1f1;
  padding: 5px 10px;
}

.aanbod {
  gap: 80px;
  display: flex;
  flex-wrap: wrap;
}

.loadWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}

@media (max-width: 800px) { 
  .aanbod {
    justify-content: center;
    display: block;
    width: calc(100% - 30px);
  }
}