.header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header h1 {
  padding: 150px 25px 25px 25px;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--txtcolor);
  font-size: 3em;
  font-weight: 300;
  text-align: center;
  text-shadow: 0px -25px 0px rgba(255,255,255,0.25);
}