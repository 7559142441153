.intro {
  margin: 25px 0;
  font-style: italic;
}

.backbutton {
  cursor: pointer;
  font-weight: bold;
  margin: 25px 0;
}

.loadWrapper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}
